function SlideNumber( Splide, Components ) {
  const { track } = Components.Elements;

  let elm;

  function mount() {
    elm = document.createElement( 'div' );
    elm.classList = 'splide-numbers text-center';

    track.parentElement.insertBefore( elm, track.nextSibling );

    update();
    Splide.on( 'move', update );
  }

  function update() {
    elm.innerHTML = '<span>' + `${ ('0' + (Splide.index + 1)).slice(-2) }` + '</span> / <span>' + `${ ('0' + Splide.length).slice(-2) }` + '</span>';
  }

  return {
    mount,
  };
}

(function($) {

  $('a[href*="#download-roadmap"]:not([href="#"])').click(function(e) {
    e.preventDefault();
    $('#sticky-modal-cta-form').foundation('open');
  });    
  
  var screenWidth = window.innerWidth;
  var screenHeight = window.innerHeight;

  var w = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  var h = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  var left = ((screenWidth / 2) - 300);
  var top = ((screenHeight / 2) - 150);

  $('.fbsharelink').click(function(){
      var shareurl = $(this).data('shareurl');
      window.open('https://www.facebook.com/sharer/sharer.php?u='+escape(shareurl)+'&t='+document.title, '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
      return false;
  });
  $('.twsharelink').click(function(){
      var twshareurl = $(this).data('shareurl');
      var twsharetitle = $(this).data('sharetitle');
      window.open('http://twitter.com/share?text='+twsharetitle+'&url='+twshareurl+'', '_blank', 'width=600,height=300,top='+top+',left'+left+'');
      return false;
  });
  $('.lisharelink').click(function() {
      var lishareurl = $(this).data('shareurl');
      var lisharetitle = $(this).data('sharetitle');
      window.open('https://www.linkedin.com/shareArticle?mini=true&url='+lishareurl+'&title='+lisharetitle+'&source=AandS&target=new', '_blank', 'width=600,height=300,top='+ top +',left='+ left +'');
      return false;
  });
  
})( jQuery );